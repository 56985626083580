<template>
  <b-card title="Add Industry Type">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group label="Industry Type">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="name"
                  type="text"
                  placeholder="Enter Industry Type Name (Code)"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Label">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="label"
                  type="text"
                  placeholder="Enter Label"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>

          
          <b-button @click="submitIndustryType" type="submit" variant="success"
            >Save</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { sleep }from '@/utils/utils.js'

export default {
  data() {
    return {
      name:'',
      label:'',
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      // getItems: "item/getItems",
      addIndustryType: "industryType/addIndustryType",
    }),
    submitIndustryType() {
      //console.info('name ',this.name)
      this.addIndustryType({
        name:this.name,
        label:this.label,
        active:true
      }).then(async () => {
        this.$bvToast.toast("Successfully Add Industry Type", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "industry-type" });
      }).catch((error) =>{
        // console.error(error)
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      });
      
    },
  },
  computed: {
    // ...mapState({
    //   itemCatalogue: (state) => {
    //     return state.item.items.map((i) => {
    //       return { label: `${i.description} - ${i.itemModel} `, ...i }
    //       //return Object.assign({ value: i.itemModel, text: `${i.itemModel}` });
    //     });
    //   },
    // })
  },
};
</script>
<style scoped>
@media (max-width: 761px) {
  .hide-on-desktop { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (min-width: 762px) {
  .hide-on-mobile { display: none !important; }
}
</style>
